import React, { Fragment, useState } from 'react';
import ClassNames from 'classnames';
import { Label, Error } from 'components/lib';
import CreatableSelect from 'react-select/creatable';
import ReactSelect from 'react-select';
import Style from './select.module.scss';

export function Select(props) {

  let options = props.options;
  const error = props.errorMessage || 'Please select an option';

  const [newOptions, setNewOptions] = useState(new Set());

  // set the default
  if (!props.isMultiSelect && !props.required && !props.default && options?.length) {

    // if theres no default, show a please select option
    if (options && options[0]?.value === 'unselected') options.shift(0);
    options.unshift({ value: 'unselected', label: 'Please select an option' });

  }

  const change = value => {
    let valid = undefined;
    if (Array.isArray(value)) {
      valid = props.required && !value.length ? false : true;
      props.onChange(props.name, value, valid);
    }
    else {
      valid = props.required && value.value !== 'unselected';
      props.onChange(props.name, value.value, valid);
    }
  }

  const create = value => {
    const options = new Set(newOptions.add(value));
    props.onChange(props.name, (props.value || []).concat({ label: value, value }), true);
    props.onChange(props.newOptionsKey, [...options], true);
    setNewOptions(options);
  }

  const css = ClassNames([

    Style.select,
    props.className,
    props.valid === true && Style.success,
    props.valid === false && Style.error

  ]);

  return (
    <Fragment>

      <Label
        text={props.label}
        required={props.required}
        for={props.name}
      />

      <div className={css}>
        {props.creatableSelect ? (
          <CreatableSelect
            isMulti={props.isMultiSelect}
            options={props.options}
            defaultValue={props.default}
            value={props.value}
            onChange={change}
            onCreateOption={create}
          />
        ) : (
          <ReactSelect
            isMulti={props.isMultiSelect}
            options={props.options}
            defaultValue={props.default}
            value={props.updateOnChange ? props.value : undefined}
            onChange={change}
          />
        )}

        {props.valid === false && <Error message={error} className={Style.message} />}

      </div>
    </Fragment>
  );
}
