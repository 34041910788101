/***
*
*   TABLE
*   Please view the full documentation: https://docs.usegravity.app/ui/table
*
*   PROPS
*   data: array of objects for body and header (optional)
*   search: bool to show the search field
*   sort: allow the table columns to be sorted
*   loading: bool to toggle loading spinner
*   badge - object containing column name and color to add badges to column
*   show - array of columns (object key names) to show (shows all if not provided)
*   hide - array of columns (object key names) to hide
*   actions: object with edit/delete keys set to callback functions (optional)
*
**********/

import React, { Fragment, useState, useEffect } from 'react';
import { Loader, Search, DateInput } from 'components/lib';
import { Header } from './header';
import { Body } from './body';
import Style from './table.module.scss';

export function Table(props) {

  // state
  const [header, setHeader] = useState(null);
  const [body, setBody] = useState(null);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    if (props.data) {

      // create the headers
      let header = props.data.header || [];

      if (!header.length) {
        for (let key in props.data[0]) {
          header.push({

            name: key,
            title: key.replace(/_/g, ' '),
            sort: key === 'actions' || props.sort === false ? false : true

          });
        }
      }

      setBody(props.data.data || props.data);
      setHeader(header);

    }
  }, [props.data, props.sort]);

  // loading
  if (props.loading) {
    return (
      <div className={Style.loading}>
        <Loader />
      </div>
    );
  }

  // no data
  if (!header && !body)
    return false

  const sort = (column, direction) => {

    const rows = filter.length ? [...filter] : [...body];

    rows.sort((a, b) => {

      if (a[column] && b[column]) {

        a[column].badge ?
          a = a[column].label : a = a[column];

        b[column].badge ?
          b = b[column].label : b = b[column];

        if (direction === 'desc') {

          if (a > b) return -1;
          if (a < b) return 1;
          else return 0;

        }
        else {

          if (a < b) return -1;
          if (a > b) return 1;
          else return 0;

        }
      }
      else {

        return false;

      }
    });

    filter ? setFilter(rows) : setBody(rows);

  }

  const search = term => {

    // search each cell in each row &
    // update state to show only filtered rows
    let rowsToShow = [];

    body.forEach(row => {
      for (let cell in row) {
        if (row[cell]?.toString().toLowerCase().includes(term.toLowerCase())) {

          if (!rowsToShow.includes(row))
            rowsToShow.push(row);

        }
      }
    })

    setFilter(rowsToShow);

  }

  const editRowCallback = (res, row) => {

    let state = [...body];
    let stateRow = state[state.findIndex(x => (x.id || x._id) === (row.id || row._id))];
    Object.keys(res).map(key => stateRow[key] = (res[key].value ?? (res[key].type === "switch" && res[key].default)));
    setBody(state);

  }

  const addCreditsRowCallback = (res, row) => {

    let state = [...body];
    let stateRow = state[state.findIndex(x => x.id === row.id)];
    stateRow["ai_credits"] += +res["credits"].value;

    setBody(state);

  }

  const deleteRowCallback = (res, row) => {

    setBody(prevBody => {
      prevBody.splice(prevBody.findIndex(x => (x.id || x._id) === (row.id || row._id)), 1);
      return prevBody;
    });

  }

  const toggleStateRowCallback = (res, row) => {
    const key = Object.keys(res)[0];
    let state = [...body];
    let stateRow = state[state.findIndex(x => (x.id || x._id) === (row.id || row._id))];

    stateRow[key] = res[key].value;
    setBody(state);
  }

  return (
    <Fragment>
      {props.dateFilter &&
        <DateInput
          className={Style.date}
          type='range'
          placeholder='Filter by date range'
          onChange={(_, dates) => props.dateFilter(dates)}
          clear
        />
      }

      {props.search &&
        <Search className={Style.search} callback={search} throttle={props.throttle} />}

      <table className={!props.naked && Style.table}>

        {header &&
          <Header
            data={header}
            callback={sort}
            show={props.show}
            hide={props.hide}
            actions={props.actions}
          />
        }
        {body &&
          <Body
            data={filter ? filter : body}
            show={props.show}
            hide={props.hide}
            badge={props.badge}
            actions={{

              edit: props.actions?.edit,
              addCredits: props.actions?.addCredits,
              view: props.actions?.view,
              delete: props.actions?.delete,
              email: props.actions?.email,
              custom: props.actions?.custom

            }}
            callback={{

              edit: editRowCallback,
              addCredits: addCreditsRowCallback,
              delete: deleteRowCallback,
              toggleState: toggleStateRowCallback

            }}
          />
        }
      </table>
    </Fragment>
  );
}
