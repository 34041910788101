/***
*
*   APP DATA NAV
*
**********/

import React from 'react';
import { SubNav } from 'components/lib';

export function AppDataNav(props){

  return(
    <SubNav items={[

      { link: '/app-data/logs', label: 'Logs', permission: "master" },
      { link: '/app-data/events', label: 'Events', permission: "master" }

    ]}/>
  );
}