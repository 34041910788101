
import Style from './accordion.module.scss';
import { useState } from 'react';
import { Icon } from 'components/lib';


export function Accordion(props) {
    const [open, setOpen] = useState(props.initialOpen || false);

    return (
        <div className={Style.accordion}>
            <button onClick={() => setOpen(prev => !prev)}>
                <span>{props.heading}</span>
                {open
                    ? <Icon
                        className='icon'
                        image='chevron-up'
                        size={20}
                        color='dark'
                    />
                    : <Icon
                        className='icon'
                        image='chevron-down'
                        size={20}
                        color='dark'
                    />
                }
            </button>
            {open && (
                <div className={Style.content}>
                    {props.children}
                </div>
            )}
        </div>
    )
}