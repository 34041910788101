import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
    Accordion, Animate, AuthContext, Breadcrumbs, Button,
    Card, CoreDataNav, Form, Label, Loader, Message,
    TextInput, useAPI, useParams, ViewContext
} from 'components/lib';


export function CoreDataPrompt() {
    const { id } = useParams();
    const viewContext = useContext(ViewContext);
    const inputs = useAPI('/api/inputs/input-name');
    const [inputOptions, setInputOptions] = useState();
    const [apiName, setApiName] = useState();
    const [prompt, setPrompt] = useState({ inputs: [] })
    const [aiOptions, setAiOptions] = useState({});
    const [optionalAiOptions, setOptionalAiOptions] = useState({});
    const [isValid, setIsValid] = useState({
        completion: false,
        model: false,
        prompt_type: false,
        logit_bias: false,
        logprobs: false,
        temperature: true,
        max_tokens: false,
        json_template: false,
        prompt_template: false
    })
    const [promptInputs, setPromptInputs] = useState([]);
    const [aiModels, setAiModels] = useState();

    useEffect(() => {
        const setOptions = models => {
            setAiModels(models?.map(model => ({ label: model, value: model })));
        }

        const getAiModels = async () => {
            try {
                const { data } = await axios.get('/api/ai-models');
                setOptions(data?.data?.models || []);
            } catch (err) {
                viewContext.handleError(err);
            }
        }

        getAiModels();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (inputs?.data) {
            setInputOptions(inputs.data.map(({ _id, input_name }) => ({ label: input_name, value: _id })))
        }
    }, [inputs])

    useEffect(() => {
        const processData = async ({ api_name, prompt }) => {
            setApiName(api_name);

            if (prompt) {
                const { ai_options, ...rest } = prompt;
                if (ai_options) {
                    if (ai_options.logit_bias)
                        ai_options.logit_bias = JSON.stringify(ai_options.logit_bias);

                    const { model, logit_bias, logprobs, temperature, max_tokens, ...optional } = ai_options;

                    setAiOptions({ model, logit_bias, logprobs, temperature, max_tokens });
                    setOptionalAiOptions(optional);
                }

                if (rest.json_template)
                    rest.json_template = JSON.stringify(prompt.json_template);

                if (rest.inputs) {
                    const inputs = [];
                    const promptInputs = [];

                    rest.inputs.forEach(({ input_name, _id }) => {
                        inputs.push({ label: input_name, value: _id });
                        promptInputs.push(input_name);
                    })

                    rest.inputs = inputs;
                    setPromptInputs(promptInputs);
                }

                if (rest.completion === 'chat') {
                    const jsonString = JSON.stringify(rest.prompt_template);
                    rest.prompt_template = jsonString?.replace(/(\\\\n)|(},{)/g, (_, p1) => p1 ? `\n` : `},\n{`);
                }

                setPrompt(rest);

                setIsValid({
                    completion: true,
                    model: true,
                    prompt_type: true,
                    logit_bias: !!ai_options.logit_bias,
                    logprobs: !!ai_options.logprobs,
                    temperature: true,
                    max_tokens: true,
                    json_template: !!rest.json_template,
                    prompt_template: true
                })
            }
        }

        const getApi = async () => {
            try {
                const { data } = await axios.get(`/api/apis/${id}?populated=true`);

                if (data?.data)
                    processData(data.data);

            } catch (err) {
                viewContext.handleError(err);
            }
        }

        getApi();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        if (prompt?.inputs) {
            setPromptInputs(prompt.inputs.map(({ label }) => label));
        }
    }, [prompt?.inputs])


    const handleChange = (setState, data) => {
        setIsValid(prev => ({ ...prev, [data.input]: data.valid }));
        setState(prev => ({ ...prev, [data.input]: data.value }));
    }

    const saveChanges = async () => {
        try {
            let valid = true;

            for (const key in isValid) {
                if (key === 'logit_bias' && isValid.prompt_type && prompt.prompt_type !== 'classification')
                    continue;

                if (key === 'logprobs' &&
                    ((isValid.completion && prompt.completion !== 'text') ||
                        (isValid.prompt_type && prompt.prompt_type !== 'classification'))
                )
                    continue;

                if (key === 'json_template' && isValid.prompt_type && prompt.prompt_type !== 'json')
                    continue;

                if (key === 'temperature' && !isValid.temperature && aiOptions.temperature === undefined)
                    continue;

                if (key === 'inputs')
                    continue;

                if (!isValid[key]) {
                    viewContext.notification.show('Please check inputs are correct', 'error');
                    valid = false;
                    break;
                }
            }

            if (valid) {
                await axios.patch(`/api/apis/${id}/prompt`, { prompt, aiOptions, optionalAiOptions });
                viewContext.notification.show('Prompt saved', 'success', true);
            }

        } catch (err) {
            viewContext.handleError(err);
        }
    }

    if (!apiName || !prompt || aiModels === undefined) return <Loader />

    return (
        <div className='CoreDataPromptAiPrompt'>
            <CoreDataNav />
            <Breadcrumbs items={[
                { name: 'APIs', url: '/core-data/apis' },
                { name: apiName + ' prompt', url: `/core-data/apis/${id}/prompt` }
            ]} />
            <Animate>
                <Card restrictWidth inline>
                    <Form
                        data={{
                            completion: {
                                type: 'select',
                                label: 'Completion',
                                options: [
                                    { label: 'text', value: 'text' },
                                    { label: 'chat', value: 'chat' }
                                ],
                                value: { label: prompt?.completion, value: prompt?.completion },
                                required: true,
                                errorMessage: 'Please select a completion endpoint'
                            }
                        }}
                        updateOnChange={true}
                        onChange={data => handleChange(setPrompt, data)}
                    />
                    <Form
                        data={{
                            model: {
                                type: 'select',
                                label: 'AI model',
                                options: aiModels,
                                value: { label: aiOptions?.model, value: aiOptions?.model },
                                required: true,
                                errorMessage: 'Please select an ai model'
                            }
                        }}
                        updateOnChange={true}
                        onChange={data => handleChange(setAiOptions, data)}
                    />
                    <Form
                        data={{
                            prompt_type: {
                                type: 'select',
                                label: 'Prompt Type',
                                options: [
                                    { label: 'standard', value: 'standard' },
                                    { label: 'json', value: 'json' },
                                    { label: 'classification', value: 'classification' }
                                ],
                                value: { label: prompt?.prompt_type, value: prompt?.prompt_type },
                                required: true,
                                errorMessage: 'Please select a prompt_type'
                            }
                        }}
                        updateOnChange={true}
                        onChange={data => handleChange(setPrompt, data)}
                    />

                    <Form
                        data={{
                            ...prompt?.prompt_type === 'classification' && {
                                logit_bias: {
                                    type: 'text',
                                    label: 'logit bias',
                                    value: aiOptions?.logit_bias,
                                    json: 'object',
                                    required: true,
                                    valid: aiOptions?.logit_bias === undefined ? undefined : isValid.logit_bias,
                                    errorMessage: `Please enter a ${aiOptions?.logit_bias ? 'valid JSON Object' : 'logit bias'}`
                                }
                            },
                            ...prompt?.prompt_type === 'classification' && prompt?.completion === 'text' && {
                                logprobs: {
                                    type: 'number',
                                    label: 'log probs',
                                    value: aiOptions?.logprobs,
                                    max: 5,
                                    required: true,
                                    valid: aiOptions?.logprobs === undefined ? undefined : isValid.logprobs,
                                    errorMessage: 'Please enter a log probs'
                                }
                            },
                            temperature: {
                                type: 'number',
                                label: 'Temperature (min: 0, max: 2)',
                                value: aiOptions?.temperature,
                                default: 1,
                                min: 0,
                                max: 2,
                                required: true,
                                valid: aiOptions?.temperature === undefined ? undefined : isValid.temperature,
                                errorMessage: 'Please enter a temperature value'
                            },
                            max_tokens: {
                                type: 'number',
                                label: 'Max tokens',
                                value: aiOptions?.max_tokens,
                                min: 0,
                                required: true,
                                valid: aiOptions?.max_tokens === undefined ? undefined : isValid.max_tokens,
                                errorMessage: 'Please enter the max tokens'
                            }
                        }}
                        updateOnChange={true}
                        onChange={data => handleChange(setAiOptions, data)}
                    />

                    <Form
                        data={{
                            moderation: {
                                label: 'Moderation',
                                type: 'switch',
                                value: prompt?.moderation ?? false,
                                default: prompt?.moderation ?? false
                            }
                        }}
                        updateOnChange={true}
                        onChange={data => handleChange(setPrompt, data)}
                    />

                    <Accordion heading='Advanced settings'>
                        <Form
                            data={{
                                best_of: {
                                    type: 'number',
                                    label: 'Best of (must be >= n)',
                                    value: optionalAiOptions?.best_of,
                                    default: 1,
                                    min: 1,
                                    valid: isValid.best_of,
                                    errorMessage: 'Please enter a best of value'
                                },
                                frequency_penalty: {
                                    type: 'number',
                                    label: 'Frequency penalty (min: -2, max: 2)',
                                    value: optionalAiOptions?.frequency_penalty,
                                    default: 0,
                                    min: -2,
                                    max: 2,
                                    valid: isValid.frequency_penalty,
                                    errorMessage: 'Please enter a frequency penalty'
                                },
                                n: {
                                    type: 'number',
                                    label: 'n (min: 1)',
                                    value: optionalAiOptions?.n,
                                    default: 1,
                                    min: 1,
                                    valid: isValid.n,
                                    errorMessage: 'Please enter an n value'
                                },
                                presence_penalty: {
                                    type: 'number',
                                    label: 'Presence penalty (min: -2, max: 2)',
                                    value: optionalAiOptions?.presence_penalty,
                                    default: 0,
                                    min: -2,
                                    max: 2,
                                    valid: isValid.presence_penalty,
                                    errorMessage: 'Please enter a presence penalty'
                                },
                                top_p: {
                                    type: 'number',
                                    label: 'Top p (min: 0)',
                                    value: optionalAiOptions?.top_p,
                                    default: 1,
                                    min: 0,
                                    valid: isValid.top_p,
                                    errorMessage: 'Please enter a top p'
                                }
                            }}
                            updateOnChange={true}
                            onChange={data => handleChange(setOptionalAiOptions, data)}
                        />
                        <Form
                            data={{
                                ai_credit_amount: {
                                    type: 'number',
                                    label: 'Ai credit amount (min: 0)',
                                    value: prompt?.ai_credit_amount,
                                    default: 1,
                                    min: 0,
                                    valid: isValid.ai_credit_amount,
                                    errorMessage: 'Please enter an AI credit amount'
                                }
                            }}
                            updateOnChange={true}
                            onChange={data => handleChange(setPrompt, data)}
                        />
                    </Accordion>
                </Card>
                <Card halfWidth inline noPadding transparent>
                    <Card>
                        <Form
                            data={{
                                new_inputs: {
                                    type: 'hidden'
                                },
                                inputs: {
                                    type: 'select',
                                    label: 'Inputs',
                                    options: inputOptions,
                                    value: prompt?.inputs,
                                    isMultiSelect: true,
                                    creatableSelect: true,
                                    newOptionsKey: 'new_inputs'
                                },
                                ...prompt?.prompt_type === 'json' && {
                                    json_template: {
                                        type: 'textarea',
                                        label: 'JSON template (snake_case)',
                                        value: prompt?.json_template,
                                        rows: 5,
                                        json: 'object',
                                        required: true,
                                        valid: prompt?.json_template === undefined ? undefined : isValid.json_template,
                                        errorMessage: `Please enter a ${prompt?.json_template ? 'valid JSON Object' : 'JSON template'}`
                                    }
                                },
                                prompt_template: {
                                    type: 'textarea',
                                    label: 'Prompt',
                                    value: prompt?.prompt_template,
                                    rows: 15,
                                    required: true,
                                    json: prompt?.completion === 'chat' && 'array',
                                    inputInjections: new Set([...(prompt?.prompt_type === 'json' ? ['jsonTemplate'] : []), ...promptInputs]),
                                    valid: prompt?.prompt_template === undefined ? undefined : isValid.prompt_template,
                                    errorMessage: `Please enter a ${prompt?.completion === 'chat' && prompt?.prompt_template ? 'valid JSON Array' : 'prompt'}`
                                }
                            }}
                            updateOnChange={true}
                            onChange={data => handleChange(setPrompt, data)}
                        />
                    </Card>
                    <Button
                        small
                        alignButton='right'
                        text='Save Prompt'
                        action={saveChanges}
                        color='purple'
                        marginBottom={3}
                    />
                    <Testing {...{ promptInputs, id, apiName }} />

                </Card>
            </Animate>
        </div>
    );
}

const Testing = ({ id, apiName, promptInputs }) => {
    const authContext = useContext(AuthContext);
    const [inputs, setInputs] = useState();
    const [result, setResult] = useState();
    const [values, setValues] = useState({});

    useEffect(() => {
        const form = {};
        const currentFormValues = {};

        for (const input of promptInputs) {
            const data = {
                type: 'textarea',
                label: input,
                value: values[input] || '',
                rows: 1
            }

            currentFormValues[input] = values[input] || '';
            form[input] = data;
        }

        setInputs(form);
        setValues(currentFormValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promptInputs])

    return (

        <>
            <Card title='Testing'>
                <Message type='warning' text='Save prompt before testing.' small />
                <Message title='Test the API using your API key' small>
                    <Label text='URL' />
                    <TextInput
                        value={`${authContext.api_url}/b2b/api/generate-ai/${apiName}`}
                        copy
                        readonly
                    />
                    <Label text='Request body' />
                    <TextInput
                        value={JSON.stringify({ ...values, uniqueUserId: authContext.account.name })}
                        copy
                        readonly
                    />
                </Message>
                <Form
                    data={inputs}
                    buttonText='Generate'
                    buttonColor='purple'
                    method='POST'
                    updateOnChange={true}
                    onChange={data => setValues(prev => ({ ...prev, [data.input]: data.value }))}
                    url={`${authContext.api_url}/master/api/apis/run/${id}`}
                    callback={({ data }) =>
                        setResult(typeof data?.ai_message === 'string'
                            ? data?.ai_message : JSON.stringify(data?.ai_message)
                        )
                    }
                />
            </Card>
            <Card>
                <TextInput type='textarea' label='Result' value={result} rows={5} readonly />
            </Card>
        </>
    )
}