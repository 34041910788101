/***
*
*   LOG/DETAIL
*   View the details of an application log.
*
**********/

import React, { useState, useEffect } from 'react';
import { AppDataNav, Card, Form, useAPI, useParams } from 'components/lib';

export function LogDetail(props) {

  const { id } = useParams();
  const [form, setForm] = useState(null);
  const log = useAPI(`/api/log/${id}`)

  useEffect(() => {
    if (log.data?.length) {

      const f = {};

      Object.keys(log.data[0]).forEach(key => {
        f[key] = {
          label: key,
          value: log.data[0][key],
          type: key === 'body' ? 'textarea' : 'text',
        }
      });

      setForm(f);

    }
  }, [log.data])

  return (
    <>
      <AppDataNav />
      <Card loading={log.loading}>

        {form &&
          <Form data={form} />}

      </Card>
    </>
  );
}