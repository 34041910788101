/***
*
*   EVENT/DETAIL
*   View the details of an individual event
*
**********/

import React, { useEffect, useState } from 'react';
import { Animate, Card, Breadcrumbs, Form, useAPI, useParams, AppDataNav } from 'components/lib';

export function EventDetail(props) {

  const { group, id } = useParams();
  const event = useAPI(`/api/event/${id}`);
  const [form, setForm] = useState(null);

  useEffect(() => {
    if (event.data?.length) {

      let time = event.data[0].time.split('T');
      time = time[0] + ' ' + time[1].split('.')[0];

      const f = {};
      Object.keys(event.data[0]).forEach(key => {
        f[key] = {
          label: key,
          value: event.data[0][key],
          type: event.data[0][key] ? 'text' : null,
        }
      });

      if (f.metadata?.value)
        f.metadata.type = 'textarea';

      f.time.value = time;

      setForm(f);
    }
  }, [event.data])

  return (
    <>
      <AppDataNav />
      <Animate>
        <Breadcrumbs items={[
          { name: 'groups', url: '/app-data/events' },
          { name: group, url: `/app-data/events/${group}` },
          { name: 'detail', url: `/app-data/events/${group}/${id}` }
        ]} />

        <Card loading={event.loading}>

          {form &&
            <Form data={form} />}

        </Card>
      </Animate>
    </>
  )
}