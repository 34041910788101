import React, { useEffect, useRef } from 'react';
import ClassNames from 'classnames';
import { Label, Error, Icon, Button, CopyButton } from 'components/lib';
import Style from './input.module.scss';

export function TextInput(props) {

  const error = props.errorMessage || 'Please enter a value';
  const textareaRef = useRef();

  useEffect(() => {
    if (props.value)
      validate(null, props.value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.json])

  const parseJson = (value, format) => {
    try {
      const parsed = JSON.parse(value);

      return format === 'array'
        ? Array.isArray(parsed) && parsed.length
        : typeof parsed === 'object' && parsed.constructor === Object && Object.keys(parsed).length;

    } catch (error) {
      return false;
    }
  }

  const validate = (e, text) => {

    let value = text || e?.target?.value || '';
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === '')
      valid = false;

    // input isn't required and value is blank
    if (!props.required && value === '')
      valid = true;

    if (props.required && value !== '')
      valid = true;

    if (props.required && props.matchValue && value !== props.matchValue)
      valid = false;

    if (props.json)
      valid = !!parseJson(value, props.json);

    // update the parent form
    props.onChange?.(props.name, value, valid);

  }

  // style
  const css = ClassNames([

    Style.textbox,
    props.className,
    props.valid === true && Style.success,
    props.valid === false && Style.error,
    props.rows && Style.unsetHeight

  ]);

  const divCss = ClassNames([

    Style.input,
    props.copy === true && Style.copy

  ]);

  return (
    <div className={divCss}>

      {props.label &&
        <Label
          text={props.label}
          required={props.required}
          setDangerously={props.setLabelDangerously}
          for={props.name}
        />}

      {props.type === 'textarea' ?

        <textarea
          ref={textareaRef}
          id={props.id}
          name={props.name}
          className={css}
          value={props.value}
          placeholder={props.placeholder}
          onChange={e => props.onChange?.(props.name, e.target.value, undefined)}
          onBlur={e => validate(e)}
          rows={props.rows}
          maxLength={props.max}
          readOnly={props.readonly}
        />

        :

        <input
          type='text'
          id={props.id}
          name={props.name}
          className={css}
          value={props.value || ''}
          placeholder={props.placeholder}
          onChange={e => props.onChange?.(props.name, e.target.value, undefined)}
          onBlur={e => validate(e)}
          maxLength={props.max}
          readOnly={props.readonly}
        />
      }

      {props.valid === false &&
        <Error message={error} />}

      {props.inputInjections?.map(input => (
        <Button
          className={Style.inputButton}
          key={input}
          small
          text={input}
          params={input}
          action={value => validate(null, `${textareaRef?.current?.value} \${${value}}`)}
        />
      ))}

      {props.copy &&
        <CopyButton
          value={props.value}
        />
      }

      {props.valid === true &&
        <Icon
          image='check'
          color='#8CC57D'
          className={Style.successIcon}
          size={16}
        />}

    </div>
  );
}
