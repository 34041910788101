/***
*
*   COPY BUTTON
*   Button with copy icon used to copy to clipboard
*
*   PROPS
*   className: pass a custom class object
*   value: value to be copied to clipboard
*
**********/

import { useState } from 'react';
import ClassNames from 'classnames';
import { Button } from "./button"
import Style from './button.module.scss';

export function CopyButton(props) {
    const [clicked, setClicked] = useState(false);

    const btnCss = ClassNames([

        props.className,
        Style.copy

    ]);

    const handleClick = () => {
        setClicked(true);

        navigator.clipboard.writeText(props.value)
            .then(
                () => {
                    setTimeout(() => {
                        setClicked(false);
                    }, 500)
                },
                (err) => {
                    console.debug(err);
                }
            )
    }

    return (
        <Button
            className={btnCss}
            icon={clicked ? 'check' : 'copy'}
            iconColor='white'
            iconButton
            action={handleClick}
        />
    )
}