/***
*
*   APP LAYOUT
*   The Admin dashboard layout containing the navigation
*   and header (title, secondary nav)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext } from 'react';
import ClassNames from 'classnames';
import { AuthContext, AppNav, Header } from 'components/lib';
import Style from './app.module.scss';
import '../layout.scss'; // globals

export function AppLayout(props){

  // context & style
  const context = useContext(AuthContext);
  const css = ClassNames([ Style.app, 'with-sidebar' ]);

  return (
    <Fragment>
      <AppNav
        type='popup'
        items={[
          { label: 'Dashboard', icon: 'bar-chart-2', link: '/dashboard' },
          { label: 'APIs', icon: 'zap', link: '/core-data' },
          { label: 'Accounts', icon: 'users', link: '/accounts' },
          { label: 'App Data', icon: 'archive', link: '/app-data'},
          { label: 'Sign Out', icon: 'log-out', action: context.signout }
        ]}
      />

      <main className={ css }>

        <Header title={ props.title } />
        { <props.children {...props.data }/> }

      </main>
    </Fragment>
  );
}
