import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
    Animate, AccountDetailNav, BlankSlateMessage, Button, Card,
    Fieldset, Loader, useParams, ViewContext
} from 'components/lib';


export function AccountPermission() {
    const { id } = useParams();
    const viewContext = useRef(useContext(ViewContext));
    const [account, setAccount] = useState();
    const [apis, setApis] = useState();

    const getPermissions = useCallback(async () => {
        try {
            const { data } = await axios.get(`/api/accounts/${id}/api-permissions`);
            setAccount(data?.data);
        } catch (error) {
            viewContext?.current &&
                viewContext.current.handleError(error);
        }
    }, [id])

    useEffect(() => {
        getPermissions();
    }, [getPermissions])

    useEffect(() => {
        const getData = async () => {
            try {
                const { data } = await axios.get('/api/apis?active=true');
                setApis(data?.data?.map(({ api_name, _id }) => ({ label: api_name, value: _id })));

            } catch (error) {
                viewContext?.current &&
                    viewContext.current.handleError(error);
            }
        }

        if (!apis) getData();
    }, [viewContext, apis])

    if (account === undefined) return <Loader />;

    return (
        <div className='ApiAccountPermission'>
            <AccountDetailNav />
            <Animate>
                <Card>
                    {account !== undefined &&
                        <ApisPanel
                            id={id}
                            viewContext={viewContext}
                            accountData={account}
                            apis={apis}
                            updatePermissions={getPermissions}
                        />
                    }
                </Card>
            </Animate>
        </div>
    );
}

const ApisPanel = ({ id, viewContext, accountData, apis, updatePermissions }) => {
    const [selected, setSelected] = useState();

    useEffect(() => {
        if (accountData?.apis) {
            setSelected(accountData.apis);
        }
    }, [accountData])

    const savePermissions = async () => {
        try {
            await axios.patch('/api/accounts/' + id, {
                api_permissions: {
                    apis: selected
                }
            });

            updatePermissions();

            viewContext?.current?.notification.show('API Permissions has been saved', 'success', true);
        } catch (error) {
            viewContext?.current &&
                viewContext.current.handleError(error);
        }
    }

    if (!apis) return null;


    if (apis.length)
        return (
            <>
                <Fieldset
                    type='checkbox'
                    name='apis'
                    default={selected ?? null}
                    options={apis}
                    onChange={(_, values) => setSelected(values)}
                />
                <Button text='Save API Permissions' action={savePermissions} center />
            </>
        )

    return <BlankSlateMessage title='No active APIs' marginTop={'20%'} />
}