/***
*
*   SIGN IN
*   Sign in 
*
**********/

import React, { useContext, useEffect } from 'react';
import { Animate, AuthContext, Form, Card, Row, useNavigate } from 'components/lib';

export function Signin(props){

  const navigate = useNavigate();
  // context
  const context = useContext(AuthContext);

  useEffect(() => {
    if (context?.account?.token) {
      navigate('/dashboard');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return(
    <Animate type='pop'>
      <Row title='Sign in to Admin'>
        <Card restrictWidth center>
          <Form
            data={{
              email: {
                label: 'Email',
                type: 'email',
                required: true,
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true,
              }
            }}
            method='POST'
            buttonText='Sign In'
            buttonColor='purple'
            url={ `${context.api_url}/api/auth` }
            callback={ context.signin }
          />

        </Card>
      </Row>
    </Animate>
  );
}
