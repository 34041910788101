/***
*
*   usePermissions hook
*   fetch, format and return the account permissions
*
**********/

import { useState, useEffect } from 'react';
import { useAPI } from 'components/lib';

export function usePermissions() {

  const [state, setState] = useState({ data: null, loading: false });
  const permissions = useAPI('/api/accounts/permissions');

  useEffect(() => {

    setState({ loading: true });

    // format permissions
    if (permissions.data) {

      let perms = [];
      permissions?.data?.forEach(perm => {

        perms.push({ value: perm, label: perm });

      });

      setState({ data: { list: perms }, loading: false });

    }
  }, [permissions]);

  return state;

}
