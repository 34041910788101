import axios from 'axios';
import { Animate, AccountDetailNav, Card, Table, TitleRow, useAPI, useParams } from 'components/lib';
import { useCallback, useEffect, useState } from 'react';


export function AccountUsage() {
    const { id } = useParams();
    const account = useAPI(`/api/ai-credits/${id}?aggregate=true`);
    const [details, setDetails] = useState();
    const [consumed, setConsumed] = useState();
    const [apiUsage, setApiUsage] = useState();
    const [hasConsumedDate, setHasConsumedDate] = useState(false);
    const [hasApiUsageDate, setHasApiUsageDate] = useState(false);

    const getConsumedCredits = useCallback(async (table, startDate, endDate) => {
        try {
            const { data } = await axios.get(`/api/api-logs/account-id/${id}/${table}${startDate ? '?startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}`);

            table === 'summary'
                ? setConsumed(data.data)
                : setApiUsage(data.data);

        } catch (error) {
            console.log(error.response?.data?.message);
        }
    }, [id])

    useEffect(() => {

        getConsumedCredits('summary');
        getConsumedCredits('api-history');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (account.data) {
            setDetails(account.data);
        }
    }, [account.data])


    return (
        <div className='ApiAccountUsage'>
            <AccountDetailNav />
            <Animate>
                <TitleRow title='Consumed Credits' />
                <Card halfWidth>
                    <Table
                        dateFilter={dates => {
                            getConsumedCredits('summary', dates?.[0], dates?.[1]);
                            setHasConsumedDate(dates ? true : false);
                        }}
                        data={{
                            data: consumed,
                            header: [
                                {
                                    name: 'total_api_calls',
                                    title: `API Calls (${hasConsumedDate ? '' : 'Today '}GMT)`
                                },
                                {
                                    name: 'consumed_credits',
                                    title: 'Consumed Credits'
                                },
                                {
                                    name: 'total_tokens_sum',
                                    title: 'Total AI Tokens'
                                },
                                {
                                    name: 'error_credits',
                                    title: 'Error Credits'
                                },
                            ]
                        }}
                        loading={!consumed}
                        show={['total_api_calls', 'consumed_credits', 'total_tokens_sum', 'error_credits']}
                    />
                </Card>

                <TitleRow title='API Usage' />
                <Card>
                    <Table
                        dateFilter={dates => {
                            getConsumedCredits('api-history', dates?.[0], dates?.[1]);
                            setHasApiUsageDate(dates ? true : false);
                        }}
                        data={{
                            data: apiUsage,
                            header: [
                                {
                                    name: 'name',
                                    title: 'Name',
                                    sort: true
                                },
                                {
                                    name: 'total_api_calls',
                                    title: `API Calls (${hasApiUsageDate ? '' : 'Today '}GMT)`
                                },
                                {
                                    name: 'total_credits_used',
                                    title: 'Credits'
                                },
                                {
                                    name: 'total_prompt_tokens',
                                    title: 'AI Prompt Tokens'
                                },
                                {
                                    name: 'total_completion_tokens',
                                    title: 'AI Completion Tokens'
                                },
                                {
                                    name: 'total_error_credits',
                                    title: 'Error Credits'
                                }
                            ]
                        }}
                        show={['name', 'total_api_calls', 'total_credits_used', 'total_prompt_tokens', 'total_completion_tokens', 'total_error_credits']}
                    />
                </Card>

                {details?.history?.length > 0 && (
                    <>
                        <TitleRow title='AI History' />
                        <Card>
                            <Table
                                search
                                data={{
                                    data: details.history,
                                    header: [
                                        {
                                            name: 'invoked_time',
                                            title: 'Invoked Time',
                                            sort: true
                                        },
                                        {
                                            name: 'name',
                                            title: 'Name',
                                        },
                                        {
                                            name: 'is_batch_call',
                                            title: 'Batch'
                                        },
                                        {
                                            name: 'used_credits',
                                            title: 'Used Credits'
                                        },
                                        {
                                            name: 'prompt_tokens',
                                            title: 'AI Prompt Tokens'
                                        },
                                        {
                                            name: 'completion_tokens',
                                            title: 'AI Completion Tokens'
                                        },
                                        {
                                            name: 'error_credits',
                                            title: 'Error Credits'
                                        }
                                    ]
                                }}
                                show={['invoked_time', 'name', 'is_batch_call', 'used_credits', 'prompt_tokens', 'completion_tokens', 'error_credits']}
                            />
                        </Card>
                    </>
                )}

            </Animate>
        </div >
    );
}