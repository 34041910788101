/***
*
*   LOGO
*   Replace the image in /images with your own logo
*
**********/

import React, { useContext } from 'react';
import ClassNames from 'classnames';
import { AuthContext, Link } from 'components/lib';
import LogoImage from './images/InspireMe_admin.svg';
import LogoMark from './images/InspireMe_logo_mark.svg';
import Style from './logo.module.scss';

export function Logo(props) {
  const authContext = useContext(AuthContext);

  const css = ClassNames([

    Style.logo,
    props.className

  ]);

  return (
    <Link
      url={authContext.account?.token
        && authContext.account?.permission === "master"
        ? "/dashboard" : '/'
      }
      className={css}
    >
      <img src={props.mark ? LogoMark : LogoImage} alt='Logo' />
    </Link>
  )
}
