/***
*
*   SUB NAV
*   Sub navigation element (located underneath the header).
*
**********/

import React from 'react';
import { NavLink } from 'react-router-dom';
import './sub.scss';

export function SubNav(props) {

  return (
    <nav className='subnav'>
      {props.items?.map((item, index) => {

        if (item.divide) return <div key={index} className='divide' />

        return (
          <NavLink
            key={item.label}
            to={item.link}
            activeclassname='active'
            className='item'>

            {item.label}

          </NavLink>
        );
      })}
    </nav>
  );
}
