import { useState, useEffect } from 'react';
import { Icon, Label, Error } from 'components/lib';

import Style from './tag.module.scss';
import classNames from 'classnames';


export function TagInput(props) {
    const css = classNames([

        Style.tagContainer,
        props.inputDisabled && Style.inputDisabled,
        props.valid === true && Style.success,
        props.valid === false && Style.error

    ]);

    const error = props.errorMessage || 'Please add radio values';

    const [tags, setTags] = useState([]);
    const [value, setValue] = useState("");
    const [inputAllowed, setInputAllowed] = useState(true);

    useEffect(() => {
        if (props.value === null || props.value === undefined) {
            setTags([]);
            setValue("");
            setInputAllowed(!props.inputDisabled);
        }
        else {
            if (props.value.length === props.tagLimit || props.inputDisabled) {
                setInputAllowed(false);
            }

            setTags(props.value);
            setValue("");
        }

    }, [props.value, props.tagLimit, props.inputDisabled]);

    const addTag = event => {
        const { value } = event.target;

        if (value) {
            const trimmed = value.trim();
            if (!tags.includes(trimmed)) {
                const update = [...tags, trimmed];
                props.onChange?.(props.name, update, props.min && tags.length === props.tagLimit - 1);
                setTags(update);
                if (tags.length === props.tagLimit - 1) {
                    setInputAllowed(false);
                }
            }
            setValue("");
        }
    }

    const removeTag = (index, tag) => {
        if (props.inputDisabled) {
            props.handleDelete(tag);
        }
        else {
            const tagsCopy = [...tags];
            tagsCopy.splice(index -= (props.inputDisabled ? 1 : 0), 1);

            let valid = true;
            if (tagsCopy.length === 0 || (props.min && tagsCopy.length !== props.min))
                valid = props.required ? false : undefined;
    
            props.onChange?.(props.name, tagsCopy, valid);
            setTags(tagsCopy);
            setInputAllowed(true);
        }
    }

    const handleChange = event => {
        setValue(event.target.value);
    }

    const handleKeyDown = event => {
        const key = event.key;
        if (key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            event.target.value && addTag(event);
        }
    }

    const handleBlur = event => {
        addTag(event);
    }

    return (
        <>
            {!props.inputDisabled &&
                <Label
                    text={props.label}
                    required={props.required}
                    setDangerously={props.setLabelDangerously}
                    for={props.name}
                />
            }

            <div className={css}>
                <ul>
                    {(props.tags || tags).map((tag, index) => (
                        <li className={Style.tag} key={tag.label || tag}>
                            {tag.label || tag}
                            <button type="button" onClick={() => removeTag(index, tag)}>
                                <Icon
                                    image='x'
                                    color='white'
                                    size={10}
                                />
                            </button>
                        </li>
                    ))}
                    {inputAllowed &&
                        <li className={Style.inputContainer}>
                            <input
                                type="text"
                                placeholder={props.placeholder}
                                data-length={tags.length}
                                name={props.name}
                                value={value}
                                maxLength={props.maxLength}
                                onKeyDown={handleKeyDown}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={props.required && tags.length === 0}
                            />
                        </li>
                    }
                </ul>
            </div>

            {props.valid === false && <Error message={error} />}
        </>
    );
}