import { useContext } from 'react';
import { Animate, Button, Card, CoreDataNav, Table, useAPI, useNavigate, ViewContext } from 'components/lib';


export function CoreDataTypes() {
    const navigate = useNavigate();
    const viewContext = useContext(ViewContext);
    const types = useAPI('/api/apis');

    const addType = () => {
        viewContext.modal.show({

            title: 'Create API',
            form: {
                api_name: {
                    label: 'API Name (kebab case)',
                    type: 'text',
                    value: "",
                    required: true,
                    errorMessage: 'Please enter an API name'
                }
            },
            buttonText: 'Create',
            buttonColor: 'purple',
            url: `/api/apis`,
            method: 'POST'

        }, (_, data) => 
            navigate(`/core-data/apis/${data._id}/prompt`, { state: data })
        );
    }

    const activateType = (data, callback) => {
        const activeState = data.active ? 'Deactivate' : 'Activate';
        viewContext.modal.show({
            title: `${activeState} API`,
            form: {
                active: {
                    type: 'hidden',
                    value: !data.active
                }
            },
            buttonText: activeState,
            text: `Are you sure you want to ${activeState.toLowerCase()} ${data.api_name}?`,
            url: `/api/apis/${data._id}/active`,
            method: 'PATCH',
            destructive: data.active
        }, (res) => {

            viewContext.notification.show(data.api_name + ' was updated', 'success', true);
            callback(res);

        });
    }

    const editType = (data, callback) => {

        viewContext.modal.show({

            title: 'Edit API',
            form: {
                api_name: {
                    label: 'API Name (kebab case)',
                    type: 'text',
                    value: data.api_name,
                    required: true,
                    errorMessage: 'Please enter an API name'
                }
            },
            buttonText: 'Save',
            buttonColor: 'purple',
            url: `/api/apis/${data._id}`,
            method: 'PATCH'

        }, (res) => {

            viewContext.notification.show(data.api_name + ' was updated', 'success', true);
            callback(res);

        });
    }

    const deleteType = (data, callback) => {
        viewContext.modal.show({
            title: 'Delete API',
            form: {
                api_name: {
                    matchValue: data.api_name,
                    placeholder: 'Enter API name',
                    required: true,
                    errorMessage: 'Please enter the API name'
                }
            },
            buttonText: 'Delete API',
            text: `Deleting an API will also delete associated prompts. Enter <strong>${data.api_name}</strong> to confirm.`,
            setDangerously: true,
            url: `/api/apis/${data._id}`,
            method: 'DELETE',
            destructive: true,


        }, () => {

            viewContext.notification.show(`${data.api_name} was deleted`, 'success', true);
            callback();

        });
    }

    return (
        <div className='CoreDataTypes'>
            <CoreDataNav />
            <Animate>
                <Card>
                    <Button
                        small
                        alignButton='right'
                        text='Create API'
                        action={addType}
                        color='purple'
                        marginBottom={3}
                    />
                    <Table
                        search
                        data={{
                            data: types.data,
                            header: [
                                { name: 'api_name', title: 'API Name', sort: true },
                                { name: 'active', title: 'Active', sort: true }
                            ]
                        }}
                        loading={types.loading}
                        show={['api_name', 'active']}
                        badge={{
                            col: 'active',
                            color: 'light-grey',
                            action: {
                                active: activateType
                            },
                            condition: [{ value: true, color: 'green' }]
                        }}
                        actions={{
                            custom: [{
                                icon: 'code',
                                action: ({ _id }) => navigate(`/core-data/apis/${_id}/prompt`),
                                tooltip: 'Prompt'
                            }],
                            edit: editType,
                            delete: deleteType,
                        }}
                    />
                </Card>
            </Animate>
        </div>
    );
}