import { Animate, AccountNav, Card, Form, useNavigate, usePermissions, Loader } from "components/lib";


export function AccountCreate() {

    const permissions = usePermissions();
    const navigate = useNavigate();

    if (permissions.loading)
        return <Loader />

    return (
        <div className='ApiAccountCreate'>
            <AccountNav />
            <Animate>
                <Card restrictWidth>
                    <Form
                        data={{
                            email: {
                                label: 'Email',
                                type: 'email',
                                required: true,
                            },
                            password: {
                                label: 'Password',
                                type: 'password',
                                required: true,
                                complexPassword: true
                            },
                            confirm_password: {
                                type: 'hidden',
                                value: null,
                            },
                            name: {
                                label: 'Account Name',
                                type: 'text',
                                required: true,
                                errorMessage: 'Please enter the account name'
                            },
                            company_name: {
                                label: 'Company Name',
                                type: 'text',
                                required: false,
                                errorMessage: 'Please enter the company name'
                            },
                            ai_credits: {
                                label: 'AI Credits',
                                type: 'number',
                                value: 100,
                                required: true,
                                errorMessage: 'Please enter AI Credit amount'
                            },
                            permission: {
                                label: 'Permission',
                                type: 'select',
                                options: permissions?.data?.list,
                                required: true,
                                errorMessage: 'Please choose a permission'
                            },
                            domains: {
                                type: 'tag',
                                label: 'Company domains',
                                placeholder: 'Enter domain',
                                required: false,
                                errorMessage: 'Please enter at least one domain'
                            },
                            ai_key_required: {
                                type: 'switch',
                                label: 'Account AI Key required',
                                default: true,
                                required: true
                            }
                        }}
                        url='/api/accounts'
                        method='POST'
                        buttonText='Create Account'
                        buttonColor='purple'
                        callback={res => {
                            res && navigate(`/accounts/${res.data.data}/permissions`);
                        }}

                    />
                </Card>
            </Animate>
        </div>
    );
}