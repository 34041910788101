import React from 'react';
import Axios from 'axios';

// components
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { PrivateRoute, AuthProvider } from './auth';
import { View } from 'components/lib';
import { NotFound } from 'views/404';

const routes = [

  ...require('routes').default

]

export default function App(props) {

  const account = JSON.parse(localStorage.getItem('account'));
  Axios.defaults.baseURL = process.env.REACT_APP_ADMIN_BACKEND_URL || 'http://localhost:5001';

  if (account?.token) {

    // add auth token to api header calls
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + account.token;

  }

  // render the routes
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {routes.map(route => {

            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  route.redirect
                    ? <Navigate to={route.redirect} replace />
                    : (
                      route.permission ?
                        <PrivateRoute permission={route.permission}>
                          <View display={route.view} layout={route.layout} title={route.title} />
                        </PrivateRoute> :
                        <View display={route.view} layout={route.layout} title={route.title} />
                    )
                }
              />
            )
          })}

          { /* 404 */}
          <Route path='*' element={<View display={NotFound} layout='home' title='404 Not Found' />} />

        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}
