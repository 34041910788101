import { Animate, Card, CoreDataNav, Form, useAPI } from 'components/lib';


export function CoreDataAiSettings() {
    const aiModels = useAPI('/api/ai-models');

    return (
        <div className='CoreDataAiSettings'>
            <CoreDataNav />
            <Animate>
                <Card loading={aiModels?.loading} inline restrictWidth>
                    <Form
                        data={{
                            models: {
                                type: 'tag',
                                label: 'OpenAI models',
                                value: aiModels?.data?.models,
                                placeholder: 'Enter model name',
                                required: true,
                                errorMessage: 'Please enter a model name'
                            }
                        }}
                        url='/api/ai-models'
                        method={aiModels?.data ? 'PUT' : 'POST'}
                        buttonText='Save models'
                        buttonColor='purple'
                        callback={(_, data) => aiModels.data = data}
                    />
                </Card>
            </Animate>
        </div>
    );
}