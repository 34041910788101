import { useContext } from "react";
import { Animate, AccountNav, AuthContext, Card, Table, useAPI, ViewContext } from "components/lib";
import axios from 'axios';


export function Accounts() {

    const authContext = useContext(AuthContext);
    const viewContext = useContext(ViewContext);
    const accounts = useAPI('/api/accounts');

    const editAccount = (data, callback) => {
        viewContext.modal.show({

            title: 'Edit Account',
            form: {
                email: {
                    label: 'Email',
                    type: 'email',
                    value: data.email,
                    required: true,
                    errorMessage: 'Please enter an email'
                },
                name: {
                    label: 'Account Name',
                    type: 'text',
                    value: data.name || "",
                    required: true,
                    errorMessage: 'Please enter an account name'
                },
                company_name: {
                    label: 'Company Name',
                    type: 'text',
                    value: data.company_name || "",
                    required: false,
                    errorMessage: 'Please enter a company name'
                },
                domains: {
                    type: 'tag',
                    label: 'Company domains',
                    value: data?.domains,
                    placeholder: 'Enter domain',
                    required: false,
                    errorMessage: 'Please enter at least one domain'
                }
            },
            buttonText: 'Save',
            buttonColor: 'purple',
            url: `/api/accounts/${data.id}`,
            method: 'PATCH'

        }, (res) => {

            viewContext.notification.show(data.name + ' was updated', 'success', true);
            callback(res);

        });
    }

    const closeAccount = (data, callback) => {
        viewContext.modal.show({
            title: 'Close Account',
            form: {},
            buttonText: 'Close Account',
            text: `Are you sure you want to close ${data.name}'s (${data.email}) account?`,
            url: `${authContext.api_url}/api/accounts/${data.id}`,
            method: 'DELETE',
            destructive: true,

        }, () => {

            viewContext.notification.show(`${data.name} was closed`, 'success', true);
            callback();

        });
    }

    const addCredits = (data, callback) => {
        viewContext.modal.show({

            title: 'Add Credits',
            form: {
                credits: {
                    label: 'Number of AI credits to add',
                    type: 'number',
                    value: 1,
                    min: 1,
                    required: true,
                }
            },
            buttonText: 'Add',
            url: `/api/ai-credits/account-id/${data.id}`,
            method: 'PATCH'

        }, (res) => {

            viewContext.notification.show(data.name + ' was updated', 'success', true);
            callback(res);

        });
    }

    const disableAccount = (data, callback) => {
        const disabledState = data.disabled ? 'Enable' : 'Disable';
        viewContext.modal.show({
            title: `${disabledState} Account`,
            form: {
                disabled: {
                    type: 'hidden',
                    value: !data.disabled
                }
            },
            buttonText: disabledState,
            text: `Are you sure you want to ${disabledState.toLowerCase()} ${data.name}'s account?`,
            url: `/api/accounts/${data.id}/disabled`,
            method: 'PATCH',
            destructive: !data.disabled
        }, (res) => {

            viewContext.notification.show(`${data.name}'s account was updated`, 'success', true);
            callback(res);

        });
    }

    const impersonateUser = async data => {

        try {

            const res = await axios.post(`/api/accounts/${data.id}/impersonate`);
            const token = res.data?.data?.token;
            if (token) {

                window.location = process.env.REACT_APP_USER_DASHBOARD_URL + `/signin/impersonate?token=${token}`

            }
        }
        catch (err) {

            viewContext.handleError(err);

        }
    }

    const requireKey = async (data, callback) => {
        const text = data.ai_key_required ? `allow ${data.name}'s account to use the default AI key` : `require ${data.name}'s account to use their own AI key`;
        viewContext.modal.show({
            title: data.ai_key_required ? 'Allow Default AI Key Use' : 'Require Own AI Key',
            form: {
                ai_key_required: {
                    type: 'hidden',
                    value: !data.ai_key_required
                }
            },
            buttonText: 'Save',
            text: `Are you sure you want to ${text}?`,
            url: `/api/accounts/${data.id}`,
            method: 'PATCH'
        }, (res) => {

            viewContext.notification.show(`${data.name}'s account was updated`, 'success', true);
            callback(res);

        });
    }

    return (
        <div className='ApiAccounts'>
            <AccountNav />
            <Animate>
                <Card>
                    <Table
                        search
                        data={{
                            data: accounts.data,
                            header: [
                                { name: 'name', title: 'Account name', sort: true },
                                { name: 'email', title: 'Email', sort: true },
                                { name: 'permission', title: 'Permission', sort: true },
                                { name: 'disabled', title: 'Disabled', sort: true },
                                { name: 'ai_key_required', title: 'AI Key required', sort: true },
                                { name: 'ai_credits', title: 'AI Credits', sort: true }

                            ]
                        }}
                        loading={accounts.loading}
                        show={['name', 'email', 'permission', 'disabled', 'ai_key_required', 'ai_credits']}
                        badge={{
                            col: ['disabled', 'ai_key_required'],
                            color: 'light-grey',
                            action: {
                                'disabled': disableAccount,
                                'ai_key_required': requireKey
                            },
                            condition: [{ value: true, color: 'green' }]
                        }}
                        actions={{

                            view: { url: '/accounts', col: 'id', endPath: '/overview' },
                            edit: editAccount,
                            addCredits: addCredits,
                            delete: closeAccount,
                            custom: [{ icon: 'log-in', action: impersonateUser, tooltip: 'Impersonate' }]

                        }}
                    />
                </Card>
            </Animate>
        </div>
    );
}