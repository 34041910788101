import React from 'react';
import { Button, Badge } from 'components/lib';
import Style from './table.module.scss';

export function Body(props) {

  if (props.data?.length) {
    return (
      <tbody>
        {props.data.map((row, index) => {

          return <Row {...props} data={row} key={index} />

        })}
      </tbody>
    );
  }

  return (
    <tbody>
      <tr>
        <td colSpan='10'>No results found</td>
      </tr>
    </tbody>
  );
}

export function Row(props) {

  let row = { ...props.data }
  row.actions = row.actions || props.actions;
  const show = [...props.show, "actions"];
  const hasActions = Object.values(row.actions).some(x => (x !== undefined));

  return (
    <tr data-id={props.data.id}>
      {show.map((cell, index) => {
        // actions
        if (cell === 'actions') {
          if (hasActions) {
            return (
              <td key={index} className={Style.actions}>

                {row.actions.view &&
                  <Button icon='eye' url={`${row.actions.view.url}/${row[row.actions.view.col]}${row.actions.view.endPath ?? ''}`} tooltip='View' />
                }

                {row.actions.edit &&
                  <Button icon='edit' action={() => row.actions.edit(row, (res) => props.callback.edit(res, row))} tooltip='Edit' />
                }

                {row.actions.addCredits &&
                  <Button icon='dollar-sign' action={() => row.actions.addCredits(row, (res) => props.callback.addCredits(res, row))} tooltip='Add Credits' />
                }

                {row.actions?.custom?.map((action, i) => {
                  if (action.condition) {

                    return row[action.condition.col] === action.condition.value ?
                      <Button key={i} icon={action.icon} action={() => action.action(row)} tooltip={action.tooltip} /> : false;

                  }

                  return <Button key={i} icon={action.icon} action={() => action.action(row)} tooltip={action.tooltip} />

                })}

                {row.actions.download &&
                  <Button icon='download' url={row.actions.download} tooltip='Download' />
                }

                {row.actions.email &&
                  <Button icon='mail' action={() => window.location = `mailto:${row.email}`} tooltip='Email' />
                }

                {row.actions.invite &&
                  <Button icon='mail' action={e => row.actions.invite(row)} tooltip='Invite' />
                }

                {row.actions.delete &&
                  <Button icon='trash' action={() => row.actions.delete(row, (res) => props.callback.delete(res, row))} tooltip='Delete' />
                }

              </td>
            );
          }
          else return false;

        }

        // hide
        if (props.hide?.includes(cell))
          return false;

        let value = row[cell];

        if (value === undefined) {
          return (
            <td key={index} />
          );
        }

        // is date/time
        if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value)) {

          const date = new Date(value).toISOString().split('T');
          const dateArr = date[0].split("-");
          value = `${dateArr.reverse().join("/")} ${date[1].split('.')[0]} GMT`;

        }

        // has badge
        if (value !== undefined && props.badge &&
          (typeof props.badge.col === 'object'
            ? props.badge.col.includes(cell)
            : cell === props.badge.col
          )
        ) {

          // default color
          let color = props.badge.color;

          // check each condition
          if (props.badge.condition) {
            props.badge.condition.forEach(cond => {

              (typeof cond.value === 'string' && typeof value === 'string') ?
                color = cond.value.toLowerCase() === value.toLowerCase() ? cond.color : color :
                color = cond.value === value ? cond.color : color;

            });
          }

          return (
            <td key={index}>
              <Badge
                text={value === true ? 'Yes' : (value === false ? 'No' : value)}
                color={color}
                onClick={props.badge.action?.[cell] ? () => props.badge.action[cell](row, (res) => props.callback.toggleState(res, row)) : null}
              />
            </td>
          );
        }

        if (Array.isArray(value)) value = value.join(", ");

        // standard cell
        return (
          <td key={index}>
            {value === true ? 'Yes' : (value === false ? 'No' : value)}
          </td>
        );
      })}
    </tr>
  );
}
