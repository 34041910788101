import React from 'react';

export function TitleRow(props){

  return (
    <section>

      { props.title && 
        <h2>
          { props.title }
        </h2> }
      
      <div>
        { props.children }
      </div>
      
    </section>
  )
}