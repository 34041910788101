/***
*
*   AUTHENTICATION
*   Auth provider to manage auth functions throughout
*   the application. <PrivateRoute> component to
*   protect internal application routes from unauthenticated
*   access.
*
**********/

import React, { useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

// auth context
export const AuthContext = React.createContext();

const permissions = require('./permissions.json');

export function AuthProvider(props) {

  const cache = JSON.parse(localStorage.getItem('account'));
  const [account, setAccount] = useState(cache);

  const signin = (res, _, impersonate) => {

    if (res.data) {

      localStorage.setItem('account', JSON.stringify(res.data));
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;

      return window.location = impersonate ?
        `${process.env.REACT_APP_USER_DASHBOARD_URL}/dashboard` : '/dashboard';

    }
  }

  const signout = async () => {

    await axios.delete(`${process.env.REACT_APP_API_URL}/api/auth`);
    localStorage.clear();
    window.location = '/signin';

  }

  const update = data => {
    let account = localStorage.getItem('account');
    if (account) {

      account = JSON.parse(account);
      for (let key in data) {

        if (Array.isArray(data[key])) {

          account[key] = data[key]

        }
        else if (typeof data[key] === 'object') {
          for (let innerKey in data[key]) {

            account[key][innerKey] = data[key][innerKey]

          }
        }
        else {

          account[key] = data[key];

        }
      }

      localStorage.setItem('account', JSON.stringify(account));
      setAccount(account);

    }
  }

  return (
    <AuthContext.Provider value={{

      account: account,
      signin: signin,
      signout: signout,
      update: update,
      api_url: process.env.REACT_APP_API_URL,
      permission: permissions[account?.permission]

    }}

      {...props} />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
  // check account
  const account = JSON.parse(localStorage.getItem('account'));

  if (account?.token && permissions[account.permission][props.permission])
    return props.children;

  // account is not authenticated
  return <Navigate to='/signin' />;

}
