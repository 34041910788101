import {
    Animate, AccountDetailNav, Card,
    Chart, Grid, List, Table, TitleRow, useAPI, useParams
} from 'components/lib';


export function AccountOverview() {
    const { id } = useParams();
    const account = useAPI('/api/accounts/' + id);
    const usage = useAPI(`/api/metrics/${id}/api-logs`);
    const tokens = useAPI(`/api/metrics/${id}/tokens`);


    return (
        <div className='ApiAccountDetail'>
            <AccountDetailNav />
            <Animate>
                <TitleRow title='Account Details' />
                <Card loading={account.loading}>
                    {account?.data && (
                        <Table
                            data={{
                                data: [account.data],
                                header: [
                                    { name: 'company_name', title: 'Company Name', sort: false },
                                    { name: 'id', title: 'Account Id', sort: false },
                                    { name: 'name', title: 'Account Name', sort: false },
                                    { name: 'email', title: 'Account Email', sort: false },
                                    { name: 'date_created', title: 'Date Created', sort: false },
                                ]
                            }}
                            show={['company_name', 'id', 'name', 'email', 'date_created']}
                        />
                    )}
                </Card>
                <Grid cols='3'>
                    <Card loading={account.loading}>
                        <Table
                            data={{
                                data: [account.data],
                                header: [
                                    { name: 'disabled', title: 'Disabled', sort: false },
                                    { name: 'ai_key_required', title: 'AI Key Required', sort: false },
                                ]
                            }}
                            show={['disabled', 'ai_key_required']}
                        />
                    </Card>
                    <Card loading={account.loading}>
                        <Table
                            data={{
                                data: [account.data],
                                header: [
                                    { name: 'ai_credits', title: 'Credits', sort: false },
                                    { name: 'used_credits', title: 'Used Credits (Total)', sort: false },
                                ]
                            }}
                            show={['ai_credits', 'used_credits']}
                        />
                    </Card>
                    <Card loading={account.loading}>
                        <h3>Listed domains</h3>
                        {account?.data?.domains?.length
                            ? <List items={account.data.domains} />
                            : <p>No domains listed</p>
                        }
                    </Card>
                </Grid>

                <Card title='Total API calls and AI Credit usage for the past 30 days'>
                    <Chart
                        type='line'
                        data={usage.data}
                        color={['red']}
                        loading={usage.loading}
                    />
                </Card>

                <Card title='AI Total Token usage for the past 30 days'>
                    <Chart
                        type='line'
                        data={tokens.data}
                        color={['red']}
                        loading={tokens.loading}
                    />
                </Card>
            </Animate>
        </div>
    );
}