/***
*
*   API Keys List
*   List & manage the API keys
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import {
  ViewContext, Animate, AccountDetailNav, Button, Card, Table,
  Loader, useAPI, AuthContext
} from 'components/lib';


export function AccountApiKey(props) {
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const fetch = useAPI(authContext.api_url + `/api/key?account=${id}`);
  const [apiKey, setApiKey] = useState();

  useEffect(() => {

    if (fetch?.data)
      setApiKey(fetch.data);

  }, [fetch]);

  if (fetch.loading) return <Loader />;

  return (
    <Fragment>
      <AccountDetailNav />

      <Animate>
        <ApiKeySection
          loading={fetch.loading}
          apiKey={apiKey}
          id={id}
          urlBase={authContext.api_url}
          handleKey={data => setApiKey(prev => data ? { ...prev, ...data } : data)}
        />
      </Animate>
    </Fragment>
  );
}

const ApiKeySection = (props) => {
  const viewContext = useContext(ViewContext);

  const revoke = data => {
    viewContext.modal.show({
      title: 'Revoke API Key',
      form: {
        active: {
          type: 'hidden',
          value: false,
        }
      },
      text: 'Are you sure you want to revoke this API Key? This action cannot be undone.',
      buttonText: 'Revoke',
      url: `${props.urlBase}/api/key/${data.id}?account=${data.account_id}`,
      destructive: true,
      method: 'PATCH',

    }, () => {

      props.handleKey({ active: false });

    });
  }

  const del = data => {
    viewContext.modal.show({
      title: 'Delete API Key',
      form: {},
      text: 'Are you sure you want to delete this API Key? This action cannot be undone.',
      buttonText: 'Delete Key',
      url: `${props.urlBase}/api/key/${data.id}?account=${data.account_id}`,
      destructive: true,
      method: 'DELETE',

    }, () => {

      props.handleKey(null);

    });
  }

  const reveal = async data => {

    // reveal the api key
    const key = (await Axios.get(`${props.urlBase}/api/key/${data.id}?account=${data.account_id}`))?.data?.data?.key;
    props.handleKey({ key });

  }

  return (
    <>
      <Button
        small
        alignButton='right'
        marginBottom={5}
        text='Generate New API Key'
        goto={{
          to: `/accounts/${props.id}/api-key/generate`,
          state: { hasKey: props.apiKey ? true : false }
        }}
        color='purple'
      />

      <Card bottomMargin>
        {props.apiKey ?
          <Table
            data={[props.apiKey]}
            loading={props.loading}
            show={['key', 'active']}
            actions={{
              custom: [

                { icon: 'eye', action: reveal, tooltip: 'Reveal' },
                { icon: 'rotate-ccw', action: revoke, condition: { col: 'active', value: true }, tooltip: 'Revoke' }
              ],
              delete: del,
            }}
            badge={{
              col: 'active', color: 'green', condition: [

                { value: true, color: 'green' },
                { value: false, color: 'red' }

              ]
            }}
          />
          : <p>No API Key generated</p>
        }
      </Card>
    </>
  )
}