/***
*
*   CORE DATA NAV
*
**********/

import React from 'react';
import { SubNav } from 'components/lib';

export function CoreDataNav(props) {

  return (
    <SubNav items={[

      { link: '/core-data/apis', label: 'APIs', permission: "master" },
      { link: '/core-data/settings', label: 'AI Settings', permission: "master" }

    ]} />
  );
}