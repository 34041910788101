import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Animate, AccountNav, Card, Table, ViewContext } from 'components/lib';
import axios from 'axios';


export function AccountConsumedCredits() {
    const viewContext = useRef(useContext(ViewContext));
    const [accounts, setAccounts] = useState();
    const [hasDate, setHasDate] = useState(false);

    const getConsumedCredits = useCallback(async (startDate, endDate) => {
        try {
            const { data } = await axios.get(`/api/api-logs/summary${startDate ? '?startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}`);
            setAccounts(data.data);
        } catch (error) {
            viewContext?.current &&
                viewContext.current.handleError(error);
        }
    }, [])

    useEffect(() => {

        getConsumedCredits();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const filterDates = async dates => {
        getConsumedCredits(dates?.[0], dates?.[1]);
        setHasDate(dates ? true : false);
    }

    return (
        <div className='ApiAccountConsumedCredits'>
            <AccountNav />
            <Animate>
                <Card>
                    <Table
                        search
                        dateFilter={filterDates}
                        data={{
                            data: accounts,
                            header: [
                                { name: 'name', title: 'Account Name', sort: true },
                                { name: 'email', title: 'Email', sort: true },
                                { name: 'ai_credits', title: 'AI Credits', sort: true },
                                { name: 'total_api_calls', title: `API calls (${hasDate ? '' : 'Today '}GMT)`, sort: true },
                                { name: 'consumed_credits', title: 'Consumed Credits', sort: true },
                                { name: 'total_tokens_sum', title: 'Total AI Tokens', sort: true },
                                { name: 'error_credits', title: 'Error Credits', sort: true },
                            ]
                        }}
                        loading={!accounts}
                        show={['name', 'email', 'ai_credits', 'total_api_calls', 'consumed_credits', 'total_tokens_sum', 'error_credits']}
                        actions={{

                            view: { url: '/accounts', col: 'id', endPath: '/overview' },

                        }}
                    />
                </Card>
            </Animate>
        </div>
    );
}