import classNames from 'classnames';
import Style from './tooltip.module.scss';

export function Tooltip({ text, show }) {

    const css = classNames([

        Style.tooltip,
        show && Style.visible,

    ]);

    return (
        <>
            <div className={css}>
                {text}
            </div>
            <span className={Style.arrow} />
        </>
    );
}