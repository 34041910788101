/***
*
*   ACCOUNT NAV
*
**********/

import React from 'react';
import { SubNav, useParams } from 'components/lib';

export function AccountNav(props){

  return(
    <SubNav items={[

      { link: '/accounts/overview', label: 'Accounts', permission: "master" },
      { link: '/accounts/consumed-credits', label: 'Consumed Credits', permission: "master" },
      { link: '/accounts/create', label: 'Create', permission: "master" },

    ]}/>
  );
}

export function AccountDetailNav(props){
  const { id } = useParams();

  return(
    <SubNav items={[

      { link: `/accounts/${id}/overview`, label: 'Overview', permission: 'master' },
      { link: `/accounts/${id}/permissions`, label: 'API Permissions', permission: 'master' },
      { link: `/accounts/${id}/api-key`, label: 'API Keys', permission: 'master' },
      { link: `/accounts/${id}/usage`, label: 'Usage', permission: 'master' },

    ]}/>
  );
}