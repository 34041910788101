import { Signin } from 'views/signin';
import { Dashboard } from 'views/dashboard';

import { Logs } from 'views/log/list';
import { LogDetail } from 'views/log/detail';

import { EventGroups } from 'views/event/groups';
import { Events } from 'views/event/list';
import { EventDetail } from 'views/event/detail';

import { Accounts } from 'views/account/list';
import { AccountConsumedCredits } from 'views/account/consumed';
import { AccountCreate } from 'views/account/create';
import { AccountOverview } from 'views/account/overview';
import { AccountPermission } from 'views/account/permission';
import { AccountApiKey } from 'views/account/apikey/list';
import { AccountApiKeyCreate } from 'views/account/apikey/create';
import { AccountUsage } from 'views/account/usage';

import { CoreDataTypes } from 'views/coreData/api/list';
import { CoreDataPrompt } from 'views/coreData/api/prompt';
import { CoreDataAiSettings } from 'views/coreData/aiSettings';


const Routes = [
  {
    path: '/',
    view: Signin,
    layout: 'auth',
    title: 'Admin'
  },
  {
    path: '/signin',
    view: Signin,
    layout: 'auth',
    title: 'Admin'
  },
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'master',
    title: 'Admin'
  },
  {
    path: '/app-data',
    redirect: '/app-data/logs'
  },
  {
    path: '/app-data/logs',
    view: Logs,
    layout: 'app',
    permission: 'master',
    title: 'App Logs'
  },
  {
    path: '/app-data/logs/:id',
    view: LogDetail,
    layout: 'app',
    permission: 'master',
    title: 'Log Detail'
  },
  {
    path: '/app-data/events',
    view: EventGroups,
    layout: 'app',
    permission: 'master',
    title: 'Events'
  },
  {
    path: '/app-data/events/:group',
    view: Events,
    layout: 'app',
    permission: 'master',
    title: 'Event'
  },
  {
    path: '/app-data/events/:group/:id',
    view: EventDetail,
    layout: 'app',
    permission: 'master',
    title: 'Event Detail'
  },
  {
    path: '/accounts',
    redirect: '/accounts/overview'
  },
  {
    path: '/accounts/overview',
    view: Accounts,
    layout: 'app',
    permission: 'master',
    title: 'Accounts'
  },
  {
    path: '/accounts/consumed-credits',
    view: AccountConsumedCredits,
    layout: 'app',
    permission: 'master',
    title: 'Accounts - Consumed Credits'
  },
  {
    path: '/accounts/create',
    view: AccountCreate,
    layout: 'app',
    permission: 'master',
    title: 'Accounts'
  },
  {
    path: '/accounts/:id/overview',
    view: AccountOverview,
    layout: 'app',
    permission: 'master',
    title: 'Account Overview'
  },
  {
    path: '/accounts/:id/permissions',
    view: AccountPermission,
    layout: 'app',
    permission: 'master',
    title: 'Account API Permissions'
  },
  {
    path: '/accounts/:id/api-key',
    view: AccountApiKey,
    layout: 'app',
    permission: 'master',
    title: 'Account API Key'
  },
  {
    path: '/accounts/:id/api-key/generate',
    view: AccountApiKeyCreate,
    layout: 'app',
    permission: 'master',
    title: 'Account API Key - Generate'
  },
  {
    path: '/accounts/:id/usage',
    view: AccountUsage,
    layout: 'app',
    permission: 'master',
    title: 'Account Usage'
  },
  {
    path: '/core-data',
    redirect: '/core-data/apis'
  },
  {
    path: '/core-data/apis',
    view: CoreDataTypes,
    layout: 'app',
    permission: 'master',
    title: 'APIs'
  },
  {
    path: '/core-data/apis/:id/prompt',
    view: CoreDataPrompt,
    layout: 'app',
    permission: 'master',
    title: 'AI Prompt'
  },
  {
    path: '/core-data/settings',
    view: CoreDataAiSettings,
    layout: 'app',
    permission: 'master',
    title: 'AI Settings'
  }
]

export default Routes;