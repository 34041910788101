/***
*
*   HOME NAV
*   Navigation used on the main external website. Renders a dashboard link
*   if the user is signed in
*
**********/

import React, { useContext } from 'react';
import ClassNames from 'classnames';
import { AuthContext, Logo, Link, Content } from 'components/lib';
import Style from './home.module.scss';

export function HomeNav(props){

  // context
  const context = useContext(AuthContext);

  // style
  const css = ClassNames([

    Style.nav,
    props.transparent && Style.transparent

  ]);

  return(
    <section className={ css }>
      <Content>

        <Logo className={ Style.logo }/>

        <nav>
          { context.account?.token &&
          <Link btn small url='/dashboard' className={ Style.cta } text='Dashboard' /> }
        </nav>

      </Content>
    </section>
  );
}
