/***
*
*   API Key Editor
*   Create or edit a new/existing API key
*
**********/

import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import {
  Animate, Card, Message, Breadcrumbs, TextInput,
  Loader, useLocation, useAPI, ViewContext, Fieldset, Button, AccountDetailNav, AuthContext
} from 'components/lib';

export function AccountApiKeyCreate() {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const { id } = useParams();
  const context = useContext(ViewContext);

  // get the scopes
  const scopes = useAPI(`${authContext?.api_url}/api/scopes/api-key`);

  // state
  const [hasKey, setHasKey] = useState();
  const [newAPIKey, setNewAPIKey] = useState(false);
  const [generating, setGenerating] = useState(false);

  useEffect(() => {
    const getKey = async () => {
      const { data } = await Axios.get(`${authContext?.api_url}/api/key`);
      setHasKey(data.data?.length ? true : false);
    }

    location.state?.hasKey ? setHasKey(location.state.hasKey) : getKey();
  }, [location.state, authContext])

  const submitForm = async () => {
    setGenerating(true);
    const { data } = await Axios.post(`${authContext?.api_url}/api/key`, { account: id, scope: scopes.data });
    setNewAPIKey(data?.data?.full_key);
    setGenerating(false);
  }

  const create = () => {
    if (hasKey) {
      context.modal.show({
        title: 'Generate API key',
        form: {},
        text: 'Your existing API key will be overwritten if you generate a new key. Do you want to continue?',
        buttonText: 'Continue',
        destructive: true

      }, () => {
        submitForm();
        context.modal.hide(true);
      })
    }
    else {
      submitForm();
    }
  }

  if (scopes.loading || hasKey === undefined)
    return <Loader />

  return (
    <Animate>
      <AccountDetailNav />

      <Breadcrumbs items={[
        { name: 'API Keys', url: `/accounts/${id}/api-key` },
        { name: 'Generate API Key', url: `/accounts/${id}/api-key/generate` }
      ]} />

      {newAPIKey ?
        <Message
          title={`Account's  New API Key`}
          type='warning'
          buttonText='Back to API Keys'
          buttonLink={`/accounts/${id}/api-key`}
        >

          <TextInput value={newAPIKey} />

        </Message> :

        <Card title='Generate API Key' loading={scopes.loading}>
          {generating
            ? <Loader marginTop />
            : <>
              <Fieldset
                type='checkbox'
                label='Scope'
                disabled={true}
                default={location?.state?.scope || scopes?.data}
                options={scopes?.data}
              />
              <Button color='purple' text='Generate' center action={create} />
            </>
          }
        </Card>
      }
    </Animate>
  )
}