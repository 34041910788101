/***
*
*   DASHBOARD
*   Overview of your application usage
*
**********/

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Card, Chart, Animate, ViewContext } from 'components/lib';
import axios from 'axios';

export function Dashboard() {
  const viewContext = useRef(useContext(ViewContext));
  const [apiData, setApiData] = useState();
  const [tokensData, setTokensData] = useState();
  const [apiDateFilter, setApiDateFilter] = useState(false);
  const [tokensDateFilter, setTokensDateFilter] = useState(false);

  const getConsumedCredits = useCallback(async (graph, startDate, endDate) => {

    try {
      const { data } = await axios.get(`/api/metrics/apis/${graph}${startDate ? '?startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}`);

      graph === 'usage'
        ? setApiData(data.data)
        : setTokensData(data.data);

    } catch (error) {
      viewContext?.current &&
        viewContext.current.handleError(error);
    }
  }, [])

  useEffect(() => {

    getConsumedCredits('usage');
    getConsumedCredits('tokens');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Animate type='pop'>
      <Card
        title={'API usage' + (!apiDateFilter ? ' for the past 30 days' : '') + ' (GMT)'}
        dateFilter={dates => {
          setApiData();
          getConsumedCredits('usage', dates?.[0], dates?.[1]);
          setApiDateFilter(dates ? true : false);
        }}
      >
        <Chart
          type='bar'
          color='green'
          data={apiData}
          loading={!apiData}
        />
      </Card>

      <Card
        title={'AI Tokens usage' + (!tokensDateFilter ? ' for the past 30 days' : '') + ' (GMT)'}
        dateFilter={dates => {
          setTokensData();
          getConsumedCredits('tokens', dates?.[0], dates?.[1]);
          setTokensDateFilter(dates ? true : false);
        }}
      >
        <Chart
          type='bar'
          color='green'
          data={tokensData}
          loading={!tokensData}
        />
      </Card>
    </Animate>
  );
}
